.main-container {
  position: relative;
  background: #000000;
  padding-top: 85px;

  .drawer-content {
    height: 100%;
    overflow: auto;
  }
}

@media screen and (min-width: 768px) {
  .main-container{
    padding-top: 100px !important;
  }
}
