.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip-top {
    bottom: 100%;
}

.tooltip-bottom {
    top: 100%;
}

.tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #535353 transparent transparent transparent;
}

.tooltip-bottom::before {
    content: "";
    position: absolute;
    top: -15%;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-style: solid;
    border-color: transparent transparent #535353 transparent;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #535353;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin: 0 auto;
}
