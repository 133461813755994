$pink-color: #fa679e;
$violet-color: #9747ff;
$orange-color: #ffc33e;
$cian-color: #05eaee;
$green-color: #0fd78f;
$neutral-color: #535353;

.gauge-bar {
    position: relative;
    width: 100%;
    height: 100%;

    #rotator {
        width: 100%;
        height: 26px;
        position: relative;
        transform: rotate(0deg);

        #marker {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #e0e0e0;
            top: 0px;
            bottom: 0px;
            left: -6.5px;
            position: absolute;
        }
    }

    .OtherOSPosition {
        top: -20px;
    }

    .scores-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        width: 100%;

        .scores {
            font-weight: 400;
            font-size: 100px;
            line-height: 110%;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #ffffff;
            text-transform: capitalize;
        }

        .scores-progress {
            font-weight: 400;
            font-size: 20px;
            line-height: 160%;
            text-align: center;
        }
    }
}

.IOSPosition {
    top: -23px !important;
}

@keyframes rotation-0deg {
    0%,
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotation-60deg {
    0% {
        transform: rotate(0deg);
    }
    20%,
    100% {
        transform: rotate(60deg);
    }
}

@keyframes rotation-92deg {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(60deg);
    }
    40%,
    100% {
        transform: rotate(92deg);
    }
}

@keyframes rotation-120deg {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(60deg);
    }
    40% {
        transform: rotate(92deg);
    }
    60%,
    100% {
        transform: rotate(120deg);
    }
}

@keyframes rotation-147deg {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(60deg);
    }
    40% {
        transform: rotate(92deg);
    }
    60% {
        transform: rotate(120deg);
    }
    80%,
    100% {
        transform: rotate(147deg);
    }
}

@keyframes rotation-180deg {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(60deg);
    }
    40% {
        transform: rotate(92deg);
    }
    60% {
        transform: rotate(120deg);
    }
    80% {
        transform: rotate(147deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

.rotator-empty {
    animation: rotation-0deg;
}

.rotator-poor {
    animation-name: rotation-60deg;
}

.rotator-needs-work {
    animation-name: rotation-92deg;
}

.rotator-fair {
    animation-name: rotation-120deg;
}

.rotator-good {
    animation-name: rotation-147deg;
}

.rotator-excellent {
    animation-name: rotation-180deg;
}

#rotator {
    animation-iteration-count: 1;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes progress-poor {
    0% {
        fill: $neutral-color;
    }
    20%,
    100% {
        fill: $pink-color;
    }
}

@keyframes progress-poor-no-forwards {
    0%,
    20% {
        fill: $pink-color;
    }
    100% {
        fill: $neutral-color;
    }
}

@keyframes progress-needs-work {
    0%,
    20% {
        fill: $neutral-color;
    }
    40%,
    100% {
        fill: $violet-color;
    }
}

@keyframes progress-needs-work-no-forwards {
    0%,
    20% {
        fill: $neutral-color;
    }
    40% {
        fill: $violet-color;
    }
    60%,
    80%,
    100% {
        fill: $neutral-color;
    }
}

@keyframes progress-fair {
    0%,
    20%,
    40% {
        fill: $neutral-color;
    }
    60%,
    100% {
        fill: $orange-color;
    }
}

@keyframes progress-fair-no-forwards {
    0%,
    20%,
    40% {
        fill: $neutral-color;
    }
    60% {
        fill: $orange-color;
    }
    100% {
        fill: $neutral-color;
    }
}

@keyframes progress-good {
    0%,
    20%,
    40%,
    60% {
        fill: $neutral-color;
    }
    80%,
    100% {
        fill: $cian-color;
    }
}

@keyframes progress-good-no-forwards {
    0%,
    20%,
    40%,
    60% {
        fill: $neutral-color;
    }
    80% {
        fill: $cian-color;
    }
    100% {
        fill: $neutral-color;
    }
}

@keyframes progress-excellent {
    0%,
    20%,
    40%,
    60%,
    80% {
        fill: $neutral-color;
    }
    100% {
        fill: $green-color;
    }
}

.progress,
#marker {
    animation-duration: 5.5s;
    animation-timing-function: step-start;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

/*line 60*/

.progress-poor #line-60 {
    animation-name: progress-poor;
}

.progress-needs-work #line-60,
.progress-fair #line-60,
.progress-good #line-60,
.progress-excellent #line-60 {
    animation-name: progress-poor-no-forwards;
}

/*line 92*/

.progress-needs-work #line-92 {
    animation-name: progress-needs-work;
}

.progress-fair #line-92,
.progress-good #line-92,
.progress-excellent #line-92 {
    animation-name: progress-needs-work-no-forwards;
}

/*line 120*/

.progress-fair #line-120 {
    animation-name: progress-fair;
}

.progress-good #line-120,
.progress-excellent #line-120 {
    animation-name: progress-fair-no-forwards;
}

/*line 147*/

.progress-good #line-147 {
    animation-name: progress-good;
}

.progress-excellent #line-147 {
    animation-name: progress-good-no-forwards;
}

/*line 180*/

.progress-excellent #line-180 {
    animation-name: progress-excellent;
}

@keyframes marker-progress-poor {
    0% {
        background-color: #e0e0e0;
    }
    20%,
    100% {
        background-color: $pink-color;
    }
}

@keyframes marker-progress-needs-work {
    0% {
        background-color: #e0e0e0;
    }
    20% {
        background-color: $pink-color;
    }
    40%,
    100% {
        background-color: $violet-color;
    }
}

@keyframes marker-progress-fair {
    0% {
        background-color: #e0e0e0;
    }
    20% {
        background-color: $pink-color;
    }
    40% {
        background-color: $violet-color;
    }
    60%,
    100% {
        background-color: $orange-color;
    }
}

@keyframes marker-progress-good {
    0% {
        background-color: #e0e0e0;
    }
    20% {
        background-color: $pink-color;
    }
    40% {
        background-color: $violet-color;
    }
    60% {
        background-color: $orange-color;
    }
    80%,
    100% {
        background-color: $cian-color;
    }
}

@keyframes marker-progress-excellent {
    0% {
        background-color: #e0e0e0;
    }
    20% {
        background-color: $pink-color;
    }
    40% {
        background-color: $violet-color;
    }
    60% {
        background-color: $orange-color;
    }
    80% {
        background-color: $cian-color;
    }
    100% {
        background-color: $green-color;
    }
}

.marker-progress-poor {
    animation-name: marker-progress-poor;
}

.marker-progress-needs-work {
    animation-name: marker-progress-needs-work;
}

.marker-progress-fair {
    animation-name: marker-progress-fair;
}

.marker-progress-good {
    animation-name: marker-progress-good;
}

.marker-progress-excellent {
    animation-name: marker-progress-excellent;
}

.excellent {
    color: $green-color;
}

.good {
    color: $cian-color;
}

.fair {
    color: $orange-color;
}

.needs-work {
    color: $violet-color;
}

.poor {
    color: $pink-color;
}

@media screen and (max-width: 350px) {
    .scores {
        font-size: 75px !important;
    }

    .scores-progress {
        font-size: 18px !important;
    }
}

@media screen and (min-width: 768px) {
    .OtherOSPosition {
        top: -19px !important;
    }

    #marker {
        width: 26px !important;
        height: 26px !important;
        left: -12px !important;
    }

    .scores {
        font-size: 80px !important;
    }
}

@media screen and (min-width: 992px) {
    .scores {
        font-size: 110px !important;
    }

    .scores-progress {
        font-size: 22px !important;
    }

    .scores-container {
        bottom: -40px !important;
    }
}
