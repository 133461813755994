$pink-color: #fa679e;
$violet-color: #9747ff;
$orange-color: #ffc33e;
$cian-color: #05eaee;
$green-color: #0fd78f;

.wave {
    animation-iteration-count: 1;
    animation-duration: 4s;
    animation-timing-function: steps(1, end);
    animation-fill-mode: forwards; /*keeps the last state*/
}

.wave-up,
.wave-down {
    svg {
        width: 100%;
        height: auto;
    }

    pointer-events: none !important;
}

.wave-up {
    padding-left: 0.5%;
}

.wave-down {
    padding-top: 3%;
    padding-left: 1%;
}

/*wave-pink*/

@keyframes col-pink-850 {
    0% {
        fill: #e0e0e0;
    }
    10%,
    20% {
        fill: $pink-color;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75% {
        fill: $cian-color;
    }
    100% {
        fill: $green-color;
    }
}

.wave-pink-850 {
    animation-name: col-pink-850;
}

@keyframes col-pink-800 {
    0% {
        fill: #e0e0e0;
    }
    10%,
    20% {
        fill: $pink-color;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75%,
    100% {
        fill: $cian-color;
    }
}

.wave-pink-800 {
    animation-name: col-pink-800;
}

@keyframes col-pink-740 {
    0% {
        fill: #e0e0e0;
    }
    10%,
    20% {
        fill: $pink-color;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50%,
    100% {
        fill: $orange-color;
    }
}

.wave-pink-740 {
    animation-name: col-pink-740;
}

@keyframes col-pink-670 {
    0% {
        fill: #e0e0e0;
    }
    10%,
    20% {
        fill: $pink-color;
    }
    30%,
    35%,
    100% {
        fill: $violet-color;
    }
}

.wave-pink-670 {
    animation-name: col-pink-670;
}

@keyframes col-pink-580 {
    0% {
        fill: #e0e0e0;
    }
    10%,
    20%,
    100% {
        fill: $pink-color;
    }
}

.wave-pink-580 {
    animation-name: col-pink-580;
}

@keyframes col-pink-0 {
    0%,
    100% {
        fill: #e0e0e0;
    }
}

.wave-pink-0 {
    animation-name: col-pink-0;
}

/*wave-violet*/

@keyframes col-violet-850 {
    0% {
        fill: #e0e0e0;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75% {
        fill: $cian-color;
    }
    100% {
        fill: $green-color;
    }
}

.wave-violet-850 {
    animation-name: col-violet-850;
}

@keyframes col-violet-800 {
    0% {
        fill: #e0e0e0;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75%,
    100% {
        fill: $cian-color;
    }
}

.wave-violet-800 {
    animation-name: col-violet-800;
}

@keyframes col-violet-740 {
    0% {
        fill: #e0e0e0;
    }
    30%,
    35% {
        fill: $violet-color;
    }
    45%,
    50%,
    100% {
        fill: $orange-color;
    }
}

.wave-violet-740 {
    animation-name: col-violet-740;
}

@keyframes col-violet-670 {
    0% {
        fill: #e0e0e0;
    }
    30%,
    35%,
    100% {
        fill: $violet-color;
    }
}

.wave-violet-670 {
    animation-name: col-violet-670;
}

@keyframes col-violet-580 {
    0%,
    100% {
        fill: #e0e0e0;
    }
}

.wave-violet-580,
.wave-violet-0 {
    animation-name: col-violet-580;
}

/*wave-yellow*/

@keyframes col-yellow-850 {
    0% {
        fill: #e0e0e0;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75% {
        fill: $cian-color;
    }
    100% {
        fill: $green-color;
    }
}

.wave-yellow-850 {
    animation-name: col-yellow-850;
}

@keyframes col-yellow-800 {
    0% {
        fill: #e0e0e0;
    }
    45%,
    50% {
        fill: $orange-color;
    }
    70%,
    75%,
    100% {
        fill: $cian-color;
    }
}

.wave-yellow-800 {
    animation-name: col-yellow-800;
}

@keyframes col-yellow-740 {
    0% {
        fill: #e0e0e0;
    }
    45%,
    50%,
    100% {
        fill: $orange-color;
    }
}

.wave-yellow-740 {
    animation-name: col-yellow-740;
}

@keyframes col-yellow-670 {
    0%,
    100% {
        fill: #e0e0e0;
    }
}

.wave-yellow-670,
.wave-yellow-580,
.wave-yellow-0 {
    animation-name: col-yellow-670;
}

/*wave-cian*/

@keyframes col-cian-850 {
    0% {
        fill: #e0e0e0;
    }
    70%,
    75% {
        fill: $cian-color;
    }
    100% {
        fill: $green-color;
    }
}

.wave-cian-850 {
    animation-name: col-cian-850;
}

@keyframes col-cian-800 {
    0% {
        fill: #e0e0e0;
    }
    70%,
    75%,
    100% {
        fill: $cian-color;
    }
}

.wave-cian-800 {
    animation-name: col-cian-800;
}

@keyframes col-cian-740 {
    0%,
    100% {
        fill: #e0e0e0;
    }
}

.wave-cian-740,
.wave-cian-670,
.wave-cian-580,
.wave-cian-0 {
    animation-name: col-cian-740;
}

/*wave-green*/

@keyframes col-green-850 {
    0% {
        fill: #e0e0e0;
    }
    100% {
        fill: $green-color;
    }
}

.wave-green-850 {
    animation-name: col-green-850;
}

@keyframes col-green-800 {
    0%,
    100% {
        fill: #e0e0e0;
    }
}

.wave-green-800,
.wave-green-740,
.wave-green-670,
.wave-green-580,
.wave-green-0 {
    animation-name: col-green-800;
}

.animation-delay-pink-1 {
    animation-delay: 0.5s !important;
}

.animation-delay-pink-2 {
    animation-delay: 0.65s !important;
}

.animation-delay-violet-1 {
    animation-delay: 0.7s !important;
}

.animation-delay-violet-2 {
    animation-delay: 0.85s !important;
}

.animation-delay-yellow-1 {
    animation-delay: 0.9s !important;
}

.animation-delay-yellow-2 {
    animation-delay: 1.05s !important;
}

.animation-delay-cian-1 {
    animation-delay: 1.1s !important;
}

.animation-delay-cian-2 {
    animation-delay: 1.15s !important;
}

.animation-delay-green {
    animation-delay: 1.2s !important;
}
