.dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  height: 100%;

  .inner-container {
    height: 100%;
    width: 100%;
    padding-top: 27px;

    .title-container {
      display: flex;
      justify-content: center;
      padding: 0px 24px;
    }

    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #0fd78f;
    }

    .title,
    .user-data {
      .info {
        display: flex;
        gap: 10px;
      }
    }

    .user-data {
      display: flex;
      justify-content: space-between;
      padding: 25px 25px 0px;
      font-weight: 400;
      line-height: 120%;
      text-align: center;

      .header {
        color: white;
        font-size: 18px;
      }

      .text {
        color: #0fd78f;
        font-size: 18px;
      }

      .left-side,
      .right-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .progress-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }

    .created-on-date {
      display: flex;
      color: white;
      justify-content: center;
    }

    .recommendation {
      display: flex;
      padding: 25px 24px;
      box-sizing: border-box;
      align-items: center;
      width: 100%;
    }

    .ranges {
      display: flex;
      padding: 24px;
      box-sizing: border-box;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .left-side-data,
      .right-side-data {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .label {
          font-weight: 400;
          font-size: 20px;
          line-height: 110%;
          color: #535353;
        }

        .range {
          font-weight: 400;
          font-size: 18px;
          line-height: 110%;
          color: #ffffff;
        }
      }

      .right-side-data {
        .label,
        .range {
          text-align: end;
        }
      }

      .left-side-data {
        .label,
        .range {
          text-align: start;
        }
      }

      .next-score {
        display: none;
      }
    }

    .ranges-gap {
      margin-top: 24px;
    }

    .progress-legend-container {
      display: flex;
      justify-content: center;
      padding: 25px 25px;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 350px) {
  .title,
  .user-data {
    font-size: 18px !important;
  }

  .date,
  .week {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .spacer {
    display: flex !important;
    flex: 1 1 0;
  }

  .date {
    margin-right: 24px;
  }

  .date,
  .week {
    font-size: 22px !important;
  }

  .title-container {
    padding: 0px 25px !important;

    .title {
      font-size: 34px !important;
    }
  }

  .progress-container {
    padding: 0px 25px !important;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row !important;
    width: 100%;
    gap: 8%;
    justify-content: center;

    .waves-container {
      width: 27%;
      max-width: 400px;
    }

    .gauge-container {
      width: 38%;
      max-width: 430px;
    }

    .mobile {
      display: none !important;
    }
  }

  .recommendation {
    padding: 25px 25px;
  }

  .ranges {
    padding: 0px 25px !important;
    align-items: end !important;

    .left-side-data,
    .right-side-data {
      .label {
        font-size: 20px !important;
      }

      .range {
        font-size: 30px !important;
      }
    }

    .next-score {
      color: white;
      display: flex !important;
      justify-content: center;
      font-size: 18px;
    }
  }

  .ranges-gap {
    margin-top: 32px !important;
  }
}

@media screen and (min-width: 992px) {
  .progress-container,
  .ranges,
  .title-container {
    padding: 0px 50px !important;
  }

  .ranges-gap {
    margin-top: 24px !important;
  }

  .recommendation {
    padding: 25px 50px !important;
  }

  .created-on-date {
    margin-top: 50px;
  }

  .gauge-container {
    max-width: 500px !important;
  }

  .user-data {
    padding: 50px 50px 0px !important;

    .header {
      font-size: 22px !important;
    }

    .text {
      font-size: 24px !important;
    }
  }
}
