.toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    border: none;
    background: transparent;
    gap: 15px;
    color: #898989;

    .toggle-button-name {
        display: none;
    }
}

.toggle-button:hover {
    .show-icon {
        fill: #0fd78f;
    }

    .hide-icon {
        svg path {
            fill: #0fd78f;
        }
    }
}

@media screen and (min-width: 768px) {
    .toggle-button-name {
        display: inline !important;
        font-size: 14px;
    }
}

@media screen and (min-width: 992px) {
    .toggle-button-name{
        font-size: 16px;
    }
}
