.indicators {
    display: flex;
    padding: 25px 24px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-side-data,
    .right-side-data {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 140px;
        max-width: 40%;

        .indicators-label {
            font-weight: 400;
            line-height: 110%;
            color: #0fd78f;
        }

        .indicators-label,
        .averageValue {
            font-size: 20px;
        }

        .avgLabel,
        .progressValue {
            font-size: 18px;
        }

        .avgLabel {
            font-weight: 400;
            line-height: 110%;
            color: #535353;
        }

        .range {
            font-weight: 400;
            line-height: 110%;
            color: #ffffff;
        }
    }

    .right-side-data {
        .avgLabel,
        .range {
            text-align: end;
        }
    }

    .left-side-data {
        .indicators-label,
        .range {
            text-align: start;
        }
    }

    .toggle-chart {
        color: white;
        display: flex;
        justify-content: center;
        font-size: 18px;
    }

    .averageValue::before,
    .progressValue::before {
        content: "\200D";
    }
}

@media screen and (max-width: 350px) {
    .indicators {
        flex-direction: column;
        gap: 18px;

        .right-side-data,
        .left-side-data {
            width: 100%;
            max-width: unset !important;

            span {
                text-align: center !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .indicators {
        padding: 25px 25px !important;
    }

    .left-side-data,
    .right-side-data {
        min-width: 40%;
        width: unset !important;
    }

    .noProgressValue::before {
        content: "\200D";
    }

    .noProgressValue{
        font-size: 18px;
    }
}

@media screen and (min-width: 992px) {
    .indicators {
        padding: 25px 50px !important;
    }

    .indicators-label,
    .averageValue {
        font-size: 34px !important;
    }

    .avgLabel,
    .progressValue,
    .noProgressValue {
        font-size: 24px !important;
    }
}
