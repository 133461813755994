.legend-part {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    .line {
        width: 100px;
        height: 6px;
        border-radius: 45px;
    }

    .progress-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-align: center;
        color: white;
    }

    .progress-score {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #898989;
    }
}
