.sidenav-container {
    background: black;
    color: white;
    width: 100%;
    box-shadow: none;
    position: fixed;
    top: 0;
    right: 0;
    border: none;
    z-index: 10;
    bottom: 0;
    outline: 0;
    box-sizing: border-box;

    .sidenav-inner-container {
        width: 100%;
        height: 100%;

        .content {
            width: 100%;
            height: 100%;

            .header {
                display: flex;
                align-items: center;
                height: 85px;
                width: 100%;
                padding: 0px 25px;
                z-index: 10;
                box-sizing: border-box;
                border-bottom: 2px solid #0fd78f;
                background-color: black;

                .sidenav-header-container {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    width: 100%;

                    .title {
                        display: block;
                        color: #535353;
                        line-height: 16px;
                        font-size: 16px;
                    }
                }
            }

            .links {
                display: flex;
                overflow-y: auto;
                height: calc(100% - 86px);
                border-left: 2px solid #535353;

                .links-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    gap: 32px;
                    width: 100%;

                    .wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 32px;
                        padding: 30px 25px;
                    }

                    .url {
                        cursor: pointer;
                    }

                    .key {
                        color: #535353;
                    }

                    .link {
                        display: flex;
                        justify-content: space-between;
                        border-radius: 8px;
                        padding: 5px 25px;
                        gap: 5px;
                        cursor: pointer;

                        .week {
                            display: flex;
                            gap: 21px;
                        }
                    }

                    span {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 110%;
                        display: flex;
                        align-items: center;
                        color: #ffffff;
                    }

                    .score {
                        color: #0fd78f;
                    }
                }
            }
        }
    }
}

.active-link {
    border: 1.5px solid #0fd78f;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

@media only screen and (min-width: 500px) {
    .sidenav-container {
        width: 300px !important;
    }
}

@media only screen and (min-width: 768px) {
    .header{
        height: 100px !important;
    }

    .links{
        height: calc(100% - 101px) !important;
    }

    .links-container {
        span {
            font-size: 20px !important;
        }
    }

    .sidenav-header-container {
        .title {
            font-size: 18px !important;
        }
    }
}
