.container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-width: 40%;

  h1,
  p {
    margin: 0;
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #0fd78f;
  }

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #ffffff;
  }
}

@media screen and (min-width: 992px) {
  .title, .description {
    font-size: 28px !important;
  }
}
