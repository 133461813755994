.chart-lenend-container {
    display: flex;
    padding: 24px 25px 0px;
    justify-content: center;
    align-items: center;
}

.chart {
    display: block;
    padding: 24px 25px;

    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
}

.display-block {
    display: block;
}

.display-flex {
    display: flex;
}

.display-none {
    display: none !important;
}

.separator-container {
    padding: 25px 0px;
}

.no-data {
    color: white;
    display: flex;
    justify-content: center;
    padding: 25px;
    text-align: center;
}
