.chart-legend {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.historical,
.future,
.line {
    display: flex;
    gap: 10px;
    align-items: center;

    .green {
        background: rgb(15, 215, 143);
    }

    .black {
        background: black;
    }

    .circle-shape {
        border: 1px solid rgb(15, 215, 143);
        width: 20px;
        height: 20px;
        border-radius: 27px;
    }

    .line-shape {
        border: 0.5px solid white;
        width: 20px;
        border-radius: 27px;
        display: flex;
        align-items: center;
    }

    .name {
        color: white;
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .chart-legend {
        flex-direction: row !important;

        .name {
            font-size: 16px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .name {
        font-size: 20px !important;
    }
}
