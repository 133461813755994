.progress-legend {
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
    .progress-legend {
        flex-direction: row !important;
    }
}
