html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "ArticulatCFNormal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

@font-face {
  font-family: "ArticulatCFNormal";
  src: local("ArticulatCFNormal"),
  url("./app/fonts/ArticulatCFNormal/font.woff") format("woff"),
  url("./app/fonts/ArticulatCFNormal/font.ttf") format("truetype");
}

.e-control {
  font-family: "ArticulatCFNormal" !important;
}