.indicators {
    display: flex;
    padding: 25px 24px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .left-side-data,
    .right-side-data {
        display: flex;
        flex-direction: column;
        gap: 3px;
        min-width: 40%;

        .label {
            font-weight: 400;
            font-size: 18px;
            line-height: 110%;
            color: #535353;
        }

        .range {
            font-weight: 400;
            font-size: 18px;
            line-height: 110%;
            color: #ffffff;
        }

        .range::before{
            content: "\200D";
        }
    }

    .right-side-data {
        .label,
        .range {
            text-align: end;
        }
    }

    .left-side-data {
        .label,
        .range {
            text-align: start;
        }
    }
}

.display-block {
    display: block;
}

.display-flex {
    display: flex;
}

.display-none {
    display: none !important;
}

@media screen and (max-width: 350px) {
    .indicators{
        flex-direction: column;
        gap: 18px;

        .right-side-data, .left-side-data{
            width: 100%;

            span{
                text-align: center !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .indicators {
        padding: 25px 25px;
    }
}

@media screen and (min-width: 992px) {
    .indicators {
        padding: 25px 50px !important;
    }

    .left-side-data,
    .right-side-data {
        .label {
            font-size: 24px !important;
        }

        .range {
            font-size: 34px !important;
        }
    }
}
