.desktop {
    display: none;
}

@media screen and (min-width: 768px) {
    .waves-container {
        width: 27%;
    }

    .desktop {
        display: block !important;
    }
}
