.header-container {
    display: flex;
    align-items: center;
    height: 85px;
    padding: 0px 25px;
    position: fixed;
    top: 0;
    z-index: 10;
    right: 0;
    left: 0;
    background: #000000;
    border-bottom: 2px solid #0fd78f;
    justify-content: space-between;
    box-sizing: border-box;

    .logo-container {
        display: flex;
        justify-content: space-between;

        .desktop {
            display: none;
        }

        .title {
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            text-align: center;
            color: white;
        }
    }

    .info-container {
        display: flex;
        gap: 12px;
        align-items: center;

        .date-info {
            gap: 12px;
            display: flex;

            .date {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 110%;
                text-align: center;
                color: white;
            }
        }

        .menu {
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 768px) {
    .header-container{
        height: 100px !important;
    }

    .date {
        font-size: 18px !important;
    }

    .logo-container {
        .desktop {
            display: block !important;
        }

        .mobile {
            display: none;
        }
    }
}

@media screen and (min-width: 992px) {
    .header-container {
        padding: 0px 50px !important;
    }
}
